/**
################################
 * Component block_image_tile
 * Built with Maciste / Version 2.0
################################
*/

.block_image_tile {
  content: "";

  .g-2, .gy-2 {
    --addd-gutter-y: 1rem;
  }
}
