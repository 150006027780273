/**
################################
 * Component block_timeline_vertical
 * Built with Maciste / Version 2.0
################################
*/

.block_timeline {
  content: "";

  .steps {
    --addd-steps-number-inner-size: 6.975rem;

  }

  .step-number {
    @extend .bg-gradient-sibeg;
    padding: 0;

    .step-number-inner {
      margin: 3px;
      @include media-breakpoint-up(md) {
        margin: 5px;
      }
    }
  }

  .step:not(:last-child)::after, .step:not(:first-child)::before {
    background-color: #EF9625;
  }
}
