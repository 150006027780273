/**
################################
 * Component block_banner_image
 * Built with Maciste / Version 2.0
################################
*/

.block_banner_image {
  content: "";

  img {
    object-fit: cover;
    width: 100%;
  }
}
