// 
// user.scss
// Use this to write your custom SCSS
//


.image-fit-obj {
  display: block;
  width: 100vw;
  object-fit: cover; /* or object-fit: contain; */
}

.footer {
  p {
    margin-bottom: 0;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__inner {
    background-color: $purple;
    background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/bg-footer.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
  }


  .has_divider {
    position: relative;
    @include media-breakpoint-up(lg) {
      &:after {
        content: '';
        height: 100%;
        border: 1px solid #B74C8A;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 13px;

        display: block;
      }
    }
  }
}

.bg-gradient-sibeg {
  background-image: linear-gradient(210deg, #ffe69f 0%, #ff9e00 32%, #1cb5a7 71.89999999999999%, #054844 100%);
}

.border-verde {
  border-color: $verde !important;
}

.addd_fw {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

/* FONT SIZE TUNING */
.fs-7 {
  line-height: 1.4;

}

.swiper-pagination-bullet-active {
  background: $primary !important;
  border-radius: 50px !important;
}

.section-formati {
  &__parent_image {
    width: 70px;
    height: auto;
    @include media-breakpoint-only(md) {
      width: 50px;

    }
    @include media-breakpoint-only(lg) {
      width: 60px;

    }


  }

  &__child_image {
    width: auto;
    height: 150px;

    @include media-breakpoint-only(xs) {
      height: 95px;

    }

    @include media-breakpoint-only(md) {
      height: 85px;

    }
    @include media-breakpoint-only(lg) {
      height: 120px;

    }
  }
}

.min-height-1255 {
  @include media-breakpoint-up(lg) {
    min-height: 1255px !important;
  }
}

.has_waves_top_gray_100 {
  &:before {
    content: '';
    background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/waves/waves-gray-100.jpg');
    height: 195px;
    display: block;
    @include media-breakpoint-only(xs) {
      height: auto;
      display: block;
      padding-top: 100px;
      background-size: 100%;
      background-repeat: no-repeat;

    }
  }

  background: $gray-100;
}

.has_waves_bottom_gray_100 {
  &:after {
    content: '';
    background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/waves/waves-gray-bottom-100.jpg');
    height: 465px;
    display: block;

    @include media-breakpoint-only(xs) {
      height: auto;
      display: block;
      padding-bottom: 104px;
      background-size: 100%;
      background-repeat: no-repeat;
      margin-bottom: 50px;
    }
  }

  background: $gray-100;
}

.has_waves_top_green {
  &:before {
    content: '';
    background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/waves/waves-green.jpg');
    height: 195px;
    display: block;

    @include media-breakpoint-only(xs) {
      height: auto;
      display: block;
      padding-top: 100px;
      background-size: 100%;
      background-repeat: no-repeat;

    }

    @include media-breakpoint-only(md) {
      height: auto;
      display: block;
      padding-top: 100px;
      background-size: 100%;
      background-repeat: no-repeat;

    }
  }

  background: $verde;

  .pack_title {
    @include media-breakpoint-only(xs) {
      font-size: 14px;

    }
  }
}

.has_waves_bottom_greens {
  &:after {
    content: '';
    background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/waves/waves-green-bottom.jpg');
    height: 465px;
    display: block;

    @include media-breakpoint-only(xs) {
      height: auto;
      display: block;
      padding-bottom: 104px;
      background-size: 100%;
      background-repeat: no-repeat;
      margin-bottom: 50px;
    }
    @include media-breakpoint-only(md) {
       background-image: none;
      margin-bottom: 50px;
      height: auto;
      padding-bottom: 50px;
    }
    @include media-breakpoint-only(lg) {
      background-image: none;
      margin-bottom: 50px;
      height: auto;
      padding-bottom: 50px;
    }
  }

  background: $verde;
}

.has_waves_top_orange {
  &:before {
    content: '';
    background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/waves/waves-orange.jpg');
    height: 195px;
    display: block;
  }

  background: $orange;
}

.dropdown-menu .active>.dropdown-item, .dropdown-menu .dropdown-item.active {
  pointer-events: unset;
}

.btn {
  @include media-breakpoint-only(xs) {
    margin-bottom: 15px;
  }
}

.btn-lg {
  @include media-breakpoint-only(xs) {
    padding: 15px 30px;
    font-size: 20px;
  }
}