/**
################################
 * Component block_display_text
 * Built with Maciste / Version 2.0
################################
*/

.block_display_text {
  content: "";
}
