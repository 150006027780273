/**
################################
 * Component block_marchi
 * Built with Maciste / Version 2.0
################################
*/

.block_marchi {
  content: "";

  &__image {
    filter: saturate(0);

    &:hover {
      filter: saturate(1);
    }
  }

}
