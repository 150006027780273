/**
################################
 * Component comunicato_item
 * Built with Maciste / Version 2.0
################################
*/

.comunicato_item {
  content: "";
}
