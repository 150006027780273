/**
################################
 * Component pagination
 * Built with Maciste / Version 2.0
################################
*/

.pagination {
  ul {

    display: flex;
    justify-content: center;
    align-items: center;

    @extend .mt-lg-5;
    @extend .mt-3;

  }

  .page-item:nth-child(n+5):nth-child(-n+7) {
    display: none;
  }
}
