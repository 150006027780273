.sidebar {
  list-style: none;

  li {
    margin-bottom:15px;
  }
  a {
    text-decoration: none;
    display: block;
    margin-bottom:5px;
  }
}