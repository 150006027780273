/**
################################
 * Component promo_widget
 * Built with Maciste / Version 2.0
################################
*/

.promo_widget {
  content: "";
}
