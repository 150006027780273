/**
################################
 * Component brand_item
 * Built with Maciste / Version 2.0
################################
*/

.brand_item {
  content: "";
  background-size: cover;
  background-position: center center;
  transition: transform 0.5s;
  -webkit-transition: -webkit-transform 0.5s;
  overflow: hidden;

  &__logo_white {
    @include media-breakpoint-only(xs) {
      padding: 60px;

    }
    @include media-breakpoint-up(lg) {
      padding: 50px;

    }


  }

  &:hover {

    transform: scale(1.5);
    -webkit-transform: scale(1.1);
  }

  &:nth-child(even) {
    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }
  }

  @include media-breakpoint-only(xs) {
    margin-bottom: 30px;
  }
}