/**
################################
 * Component post_related
 * Built with Maciste / Version 2.0
################################
*/

.post_related {
  content: "";
}
