/**
################################
 * Component breadcrumbs
 * Built with Maciste / Version 2.0
################################
*/

#breadcrumbs {
  content: "";
  margin-bottom: 0;
  padding: 10px 0;
  font-size: 14px;
  a {
    color: white;
    text-decoration: none;
  }
}
