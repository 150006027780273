/**
################################
 * Component block_timeline_horizontal
 * Built with Maciste / Version 2.0
################################
*/

.block_timeline--horizontal {
  content: "";



  .steps {
   // overflow-x: scroll !important;


    .step {
      flex-grow: 0 !important;
      flex-shrink: 0 !important;
      flex-basis: 20%  !important;
    }
  }
}
