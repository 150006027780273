// 
// user-variables.scss
// Use this to override Bootstrap and Finder variables
//

// Example of a variable override to change Finder background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;


$primary: #ff006c;
$gray-900: #33354D;
$assetPath : '/wp-content/themes/sibeg2.0/assets/img/sibeg';
/* Button */

$btn-border-radius:50px;
$btn-border-radius-sm: 50px;
$btn-border-radius-lg: 50px;

$btn-font-weight:500;


/* Typography */

$lead-font-size: 1.375rem;

/* Card */

$card-border-radius: 0;
$card-border-width:0;

/* Positions */

$position-values: (
        0: 0,
        5: 5%,
        10: 10%,
        20: 20%,
        30: 30%,
        40: 40%,
        50: 50%,
        60: 60%,
        70: 70%,
        80: 80%,
        90: 90%,
        100: 100%
);
