/**
################################
 * Component page_heading_hero
 * Built with Maciste / Version 2.0
################################
*/

.page_heading_hero {
  content: "";
}
