.blocco-luca-busi {

    @include media-breakpoint-only(lg) {
      .display-6 {
        font-size:24px;
      }
      .col-lg-7 {
       }
      .col-lg-5 {
        margin-top: 145px;
      }
     }

  @include media-breakpoint-only(md) {

    .col-lg-7 {
      text-align: center !important;

      .lead {
        margin-bottom:30px;
      }
    }
    .col-lg-5 {
      text-align: center;
    }
  }


  .row {
    @include media-breakpoint-only(xs) {
    flex-direction: column-reverse !important;
    }
    @include media-breakpoint-only(sm) {
    flex-direction: column-reverse !important;
    }
    @include media-breakpoint-only(md) {
    flex-direction: column-reverse !important;
    }
  }
  img {
    margin-bottom: 0 !important;
    @include media-breakpoint-only(xs) {
      margin-top: 30px;
    }
  }
}