/**
################################
 * Component block_promozioni
 * Built with Maciste / Version 2.0
################################
*/

.block_promozioni {
  content: "";
}
