/**
################################
 * Component navbar_search
 * Built with Maciste / Version 2.0
################################
*/


.modal {
  --addd-modal-zindex:99999;
}

.navbar_search {
  #search input[type="search"] {
   // position: absolute;
    top: 50%;
    width: 100%;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0);
    font-size: 34px;
    font-weight: 300;
    text-align: center;
    border: 0px;
    margin: 0px auto;
    margin-top: -51px;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;

    @include media-breakpoint-up(lg) {
      font-size: 60px;

    }

    &::placeholder {

      color: white;
    }
  }
  #search .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 61px;
    margin-left: -45px;
  }
  #search .btn-close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    opacity: 1;
    padding: 10px 17px;
    font-size: 32px;
  }
}
