/**
################################
 * Component navbar3
 * Built with Maciste / Version 2.0
################################
*/

.navbar {
  --addd-navbar-toggler-color: #fff;


  .plus_logo {
    @media screen and (max-width: 375px) {
      img.hh_logo {
        width: 140px !important;
      }
    }
  }
}

.navbar3 {
  content: "";

  .container {
    @include media-breakpoint-only(xl) {
      //max-width: 1700px !important;
    }
  }

  .wpml-ls-statics-shortcode_actions {
    background-color: transparent !important;
    border: none;
    border-right: 2px solid rgb(255 255 255 / 10%);
    border-left: 2px solid rgb(255 255 255 / 10%);


    a {
      color: white;

      &:hover {
        span {
          color: $primary;
        }
      }
    }


    .nav-link {
      @include media-breakpoint-only(lg) {
        font-size: 1.25rem;
      }
    }
  }


  .btn-close-search {
    position: absolute;
    top: -40px;
    right: 0;
  }

  .dropdown-menu-dark, .dark-mode .dropdown-menu {
    --addd-dropdown-bg: red;
  }

  .hh_logo {
    @include media-breakpoint-down(sm) {
      max-width: 70%;
    }
  }

  .navbar {
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%)
  }

  /* SEARCH */
  .modal-content {
    background: rgb(51 53 77 / 90%);
  }
}

.megamenu {

  &__title {
    display: flex;
    justify-content: start;
    padding: 10px;
    text-decoration: none;
    font-weight: bold;
  }

  &__cta {
    text-align: right;
    display: flex;
    justify-content: end;
    padding: 10px;
    text-decoration: none;
    font-weight: bold;
  }
}


/* MEGA MENU */


#mega-menu-wrap-primary_menu, #mega-menu-wrap-primary_menu #mega-menu-primary_menu > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link {
  background: transparent !important;
}

#mega-menu-wrap-primary_menu {
  width: 100% !important;
}

/* HOVER */
#mega-menu-wrap-primary_menu #mega-menu-primary_menu > li.mega-menu-item > a.mega-menu-link:hover {
  color: $primary !important;
  background: transparent !important;
  font-weight: bold !important;
}

/* CURRENT */
#mega-menu-wrap-primary_menu #mega-menu-primary_menu > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link, #mega-menu-wrap-primary_menu #mega-menu-primary_menu > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link, #mega-menu-wrap-primary_menu #mega-menu-primary_menu > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
  font-weight: bold !important;
  color: $primary;
  background: transparent !important;

}

/* CURRENT SELECTED */
#mega-menu-wrap-primary_menu #mega-menu-primary_menu > li.mega-menu-item.mega-toggle-on > a.mega-menu-link {
  font-weight: bold !important;
  color: $primary;
  background: transparent !important;
}

/* MEGA MENU BOX*/

#mega-menu-wrap-primary_menu #mega-menu-primary_menu > li.mega-menu-megamenu > ul.mega-sub-menu {
  top: 70px !important;
  background: white !important;
}

ul.mega-menu {
  @extend .navbar-nav;
  @extend .me-auto;
  @extend .mb-2;
  @extend .mb-md-0;

  li.mega-menu-item {


    a.mega-menu-link {
      @extend .nav-link;

    }
  }
}


/* STICKY NAV */

.sibeg_sticky_nav {
  display: none;


  &.is_sticky {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;

    i {
      color: white;
    }

    .navbar-toggler-icon {
      color: white;

    }

    .wpml-ls-statics-shortcode_actions {
      background-color: transparent !important;
      border: none;
      border-right: 2px solid rgb(255 255 255 / 10%);
      border-left: 2px solid rgb(255 255 255 / 10%);

      a {
        color: white;

        &:hover {
          color: $primary;
        }
      }
    }

  }
}