/**
################################
 * Component row_base
 * Built with Maciste / Version 2.0
################################
*/

.row_base {
  content: "";
}
