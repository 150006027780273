/**
################################
 * Component card
 * Built with Maciste / Version 2.0
################################
*/

.card {
  content: "";
}
.center-cropped img{
  object-fit: none; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  width: 100%;
  max-height: 340px;

}
