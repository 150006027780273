/**
################################
 * Component block_divider_onde
 * Built with Maciste / Version 2.0
################################
*/

.block_divider_onde_top {
  position: relative;

  svg {
    position: absolute;
 //   top: -50px;
    right: 0;
    left: 0;
    z-index: 99;

    @include media-breakpoint-up(xs) {
      top: -100px !important;
    }

    @include media-breakpoint-up(md) {
      top: -80px !important;
    }
    @include media-breakpoint-up(lg) {
      top: -70px !important;
    }
    @include media-breakpoint-up(xl) {
      top: -50px !important;
    }
  }


}

.block_divider_onde_bottom {
  position: relative;

  svg {
    position: absolute;
   // bottom: 25px;
    right: 0;
    left: 0;
    z-index: 1;

    @include media-breakpoint-up(xs) {
      bottom: -85px !important;
    }

    @include media-breakpoint-up(md) {
      bottom: 0px !important;
      display: none;
    }

    @include media-breakpoint-up(lg) {
      bottom: -50px !important;
      display: block;
    }
  }
}