/**
################################
 * Component slider_news
 * Built with Maciste / Version 2.0
################################
*/

.slider_news {
  .swiper-pagination-bullet-active {
    background: #ffffff !important;

  }

  .swiper-slide {
    height: 850px;


    & > .container {
      @include media-breakpoint-up(md) {
        height: 850px;
        position: relative;
        z-index: 99999;

        .row {
          height: 850px;
        }
      }
    }

    &__content {
      @include media-breakpoint-down(md) {
        min-height: 235px;
      }

    }
  }


  &--layout-1 {


    .slider_news__onde {

      background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/slider/sibeg_slunt_slider.png');

      background-position-x: center;
      background-size: unset;
      width: 100%;
      height: 207px;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      @include media-breakpoint-only(sm) {
        height: 60px !important;
      }

      @include media-breakpoint-down(md) {
        background-size: cover;
        width: 100%;
        height: 50px;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
      }
    }

    .slider_news__swirl {

      @include media-breakpoint-down(md) {
        background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/slider/sibeg_swirl_compact_slider_mobile.png');
        background-position: center center;
        background-size: 650px;
        background-repeat: no-repeat;
        height: 155px;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 415px;
      }

      @include media-breakpoint-only(sm) {
        background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/slider/sibeg_swirl_compact_slider_sm.png');
        background-position: center center;
        background-size: 1200px;
        background-repeat: no-repeat;
        height: 110px;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 230px;
      }

      @include media-breakpoint-up(md) {
        position: absolute;
        background-position: bottom;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        height: -webkit-fill-available;
        background-size: unset;
        margin: 0 auto;
        background-repeat: no-repeat;
      }

      @include media-breakpoint-only(md) {
        background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/slider/swirl.png');

      }

      @include media-breakpoint-up(lg) {
        background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/slider/swirl.png');

      }

    }

    @include media-breakpoint-only(xs) {
      .image_xs {
        height: 500px;
        object-fit: cover;
        margin-bottom: 80px;
      }
    }

    @include media-breakpoint-only(sm) {
      .image_sm {
        height: 300px;
        background-size: cover !important;
      }
    }

    .slider_news__content {
      content: '';
    }

  }

  &--layout-2 {


    .slider_news__onde {

      background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/slider/sibeg_slunt_slider.png');

      background-position-x: center;
      background-size: unset;
      width: 100%;
      height: 207px;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      @include media-breakpoint-only(sm) {
        height: 60px !important;
      }

      @include media-breakpoint-down(md) {
        background-size: cover;
        width: 100%;
        height: 50px;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
      }
    }

    .slider_news__swirl {
      @include media-breakpoint-down(md) {
        background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/slider/sibeg_swirl_compact_slider_mobile.png');
        background-position: center center;
        background-size: 650px;
        background-repeat: no-repeat;
        height: 155px;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 415px;
      }

      @include media-breakpoint-only(sm) {
        background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/slider/sibeg_swirl_compact_slider_sm.png');
        background-position: center center;
        background-size: 1200px;
        background-repeat: no-repeat;
        height: 110px;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 230px;
      }

      @include media-breakpoint-up(md) {
        position: absolute;
        background-position: bottom;
        bottom: 0px;
        left: 0;
        right: 0;
        z-index: 9999;
        height: -webkit-fill-available;
        background-size: unset;
        margin: 0 auto;
        background-repeat: no-repeat;
      }

      @include media-breakpoint-only(md) {
        background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/slider/swirl.png');

      }
      @include media-breakpoint-up(lg) {
        background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/slider/sibeg_swirl_full.png');
        bottom: -73px;

      }

    }

    @include media-breakpoint-only(xs) {
      .image_xs {
        height: 500px;
        object-fit: cover;
        margin-bottom: 80px;
      }
    }

    @include media-breakpoint-only(sm) {
      .image_sm {
        height: 300px;
        background-size: cover !important;
      }
    }

    .slider_news__content {
      content: '';
    }

  }

  /*
    &--layout-2 {
      background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/bg-layout-2.jpg');

      .slider_news__wrapper {
        position: relative;


      }

      .slider_news__image {
        border-radius: 50%;


      }

    }

   */
}