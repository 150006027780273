.page-id-225 {
   .block_banner__first {
    @include media-breakpoint-only(xs) {
      background-size: 2650px !important;
      margin-bottom: 30px;
    }
    @include media-breakpoint-only(sm) {
       margin-bottom: 30px !important;
    }
     @include media-breakpoint-only(md) {
       margin-bottom: 50px !important;
       background-size: 2350px !important;

     }
  }

}