/**
################################
 * Component block_banner
 * Built with Maciste / Version 2.0
################################
*/

.block_banner {
  content: "";
  //background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;

  @include media-breakpoint-up(lg) {
    min-height: 520px;
    display: flex;
    align-items: center;
  }
}