/**
################################
 * Component brand_loop
 * Built with Maciste / Version 2.0
################################
*/

.brand_loop {
  content: "";

  &__item-wrapper {
    &:nth-child(even) {
      @include media-breakpoint-up(md) {
        margin-top: 30px;
      }
    }
  }
}
