/**
################################
 * Component block_ruolo_icone
 * Built with Maciste / Version 2.0
################################
*/

.block_ruolo_icone {
  content: "";
}
