/**
################################
 * Component block_icon_box
 * Built with Maciste / Version 2.0
################################
*/

.block_icon_box {
  content: "";

  @include media-breakpoint-down(md) {
    img {
      width: 200px;
    }
  }
}
