body.page-template-sostenibilita {
  .blocco {
    &--green-mobility {
      @include media-breakpoint-up(xl) {
        margin-top: -300px;
        position: relative;
      }
    }

    &--co2 {
      background-image: url($assetPath + '/pages/sostenibilita/bg-co2.jpg');
      background-repeat: no-repeat;
      background-size: contain;

      &.min-height-1255 {


        @include media-breakpoint-only(lg) {


          min-height: 1072px !important;

        }
      }

      @include media-breakpoint-only(lg) {

        background-size: auto;

      }


      @include media-breakpoint-only(lg) {

        .display-6 {
          font-size: 18px;
        }
      }

      @include media-breakpoint-only(md) {

        .display-6 {
          font-size: 18px;
        }
      }

      @include media-breakpoint-only(xs) {
        background-size: 1400px;
        background-position: center top;
        height: 890px;

        .display-7 {
          font-size: 54px;

        }
      }

      &__row {
        background: rgba(white, .8);
      }
    }
    &--riciclo-totale {
      background-image: url($assetPath + '/pages/sostenibilita/sibeg_sostenibilita_riciclototale-bg.jpg');
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .bilancio-cards {
    @include media-breakpoint-only(lg) {
      .display-6 {
        font-size: 26px;
      }
    }
  }

}