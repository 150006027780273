/**
################################
 * Component gusto_item
 * Built with Maciste / Version 2.0
################################
*/

.loop_gusti {
  align-items: flex-end;

}

.gusto_item {
  content: "";

  &__image {
    max-width: 40%;

    @include media-breakpoint-only(xs) {
      max-width: 20%;

    }
    @include media-breakpoint-only(sm) {
      max-width: 20%;

    }
    @include media-breakpoint-only(md) {
      max-width: 20%;

    }
  }
}

.packshot_wrapper {
  height: 640px;

}

.packshot_bg {
  height: 465px;
  @include media-breakpoint-only(xs) {
    height: 365px;
  }
  @include media-breakpoint-only(sm) {
    height: 365px;
  }
  @include media-breakpoint-only(md) {
    height: 365px;
  }
}

.packshot_image {
  height: 640px;
  margin-right: 25px;
  @include media-breakpoint-only(xs) {
    height: 530px;
    margin-right: 0;

  }
  @include media-breakpoint-only(sm) {
    height: 530px;
    margin-right: 0;

  }
  @include media-breakpoint-only(md) {
    height: 530px;
    margin-right: 0;

  }
}


