/**
################################
 * Component page_heading
 * Built with Maciste / Version 2.0
################################
*/

.page_heading {
  content: "";

  &__bg {
    @include media-breakpoint-only(xs) {
      height: 90px;
    }
  }

  .waterwave-canvas {
    background: $purple;
    z-index: -1;
    position: absolute;
    top: -270px;
  }

  &__image-hero {
    @include media-breakpoint-only(xs) {
      height: 400px;
    }
  }
}
