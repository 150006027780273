.page-template-produzione {
  .blocco {

    &__locale_e_sostenibile {
      background-image: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/pages/produzione/bg-locale-sostenibile.jpg');
      background-size: cover;
      background-position: center center;
    }

    &__locale_e_sostenibile__row {
      background-color: rgba(white, .8);
    }


    &__7-linee {
      @include media-breakpoint-only(xs) {
        .row {
          flex-direction: column-reverse !important;

        }
      }
    }
  }
}

