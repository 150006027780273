/**
################################
 * Component block_video
 * Built with Maciste / Version 2.0
################################
*/

.block_video {
  content: "";
}
