/**
################################
 * Component card_dati
 * Built with Maciste / Version 2.0
################################
*/

.card_dati {
  content: "";
}
