/**
################################
 * Component block_nostro_ruolo
 * Built with Maciste / Version 2.0
################################
*/

.block_nostro_ruolo {
  background: url('/wp-content/themes/sibeg2.0/assets/img/sibeg/pages/azienda/sibeg_azienda-ruolo-bg.jpg');
  background-size: cover;
  background-position: center center;


  &__gazzella {
    margin-top: -150px;
    position: relative;
    z-index:99;
  }
}
