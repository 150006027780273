/**
################################
 * Component prodotto_gusto_item
 * Built with Maciste / Version 2.0
################################
*/

.prodotto_gusto_item {
  content: "";

  img {
    max-height: 290px;
    width: auto;
  }
}
