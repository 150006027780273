/**
################################
 * Component promo_item
 * Built with Maciste / Version 2.0
################################
*/

.promo_item {
  content: "";

  &__image_blur {

    filter: blur(10px);
    opacity: 50%;
  }

  &__wrapper {
    @include media-breakpoint-only(xs) {
      overflow: hidden;
    }
  }

  img {
    margin: auto auto;
  }

}
