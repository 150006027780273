/**
################################
 * Component promo_single_content
 * Built with Maciste / Version 2.0
################################
*/

.promo_single_content {
  content: "";
}
