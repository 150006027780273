/**
################################
 * Component footer_menu
 * Built with Maciste / Version 2.0
################################
*/

.footer_menu {
  content: "";
  position: relative;

  .nav-link {
    font-size: 0.875rem;
  }
}

#menu-footer-mobile-menu {

  li a {
    display: block;

    text-align: center;
  }
}


