/**
################################
 * Component menu_mobile
 * Built with Maciste / Version 2.0
################################
*/

.menu_mobile {
  content: "";
}
