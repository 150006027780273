/**
################################
 * Component navbar_sticky
 * Built with Maciste / Version 2.0
################################
*/

.navbar_sticky {
  content: "";
}
